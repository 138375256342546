import { useContext, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";

// Home
import Home from "pages/Home";

// Welcome Page
import WelcomeTOS from "pages/LoginSystem/WelcomeTOS";
import Welcome from "pages/LoginSystem/Welcome";

// Pages
import MentalHealth from "pages/MentalHealth";
import Favourites from "pages/Favourites/Favourites";
import BrowseAllResources from "../pages/MentalHealth/Browse"
import Recommendations from "pages/MentalHealth/Recommendations"
import Mindfulness from "pages/MentalHealth/Mindfulness";

import GoBack from "pages/MentalHealth/GoBack";

// Fitness
import Fitness from "pages/MyFitness/MyFitness";
import OnDemand from "pages/MyFitness/OnDemand";
import OnCampus from "pages/MyFitness/OnCampus";
import LiveStreams from "pages/MyFitness/LiveStreams";

import RecommendationsIntro from "pages/RecommendationsV2/ReommendationsIntro";

import { studentApi, orgApi } from "api";
import AuthContext from "contexts/auth";

import { getUserIDfromCookies } from "util/getUserIdFromCookie";

//AMS Clubs
// import AMSClubs from "pages/AMSClubs/AMSClubs";
// import Clubs from "pages/AMSClubs/AMSTabs/Clubs";
// import ClubsResources from "pages/AMSClubs/AMSTabs/ClubsResources";

import NavBar from "components/NavBar/NavBar";
import Footer from "components/Footer/Footer";
import MessageBar from "components/MessageBar/MessageBar";
import Hotjar from "components/AnalyticsTools/Hotjar/hotjar";
import StaffAccountRecord from "components/AnalyticsTools/StaffAccountRecord/staffAccountRecord";

import VideoInvite from "components/VideoInvite/videoInvite";
import withAuthentication from "hoc/withAuthentication";
import LoadingPage from "pages/LoginSystem/LoadingPage"
import BrowserNotifications from "components/BrowserNotifications";
import CreateIntent from "pages/RecommendationsV2/CreateIntent";
import RecommendationsForIntent from "pages/RecommendationsV2/RecommendationsForIntent";
import WaitForReccomendations from "pages/MentalHealth/WaitForReccomendations";

const AuthenticatedRoutes = () => {
  const [loadingUser, setLoadingUser] = useState(true);
  const [welcomesWithTOS, setWelcomesWithTOS] = useState(false)
  const { user, org, setOrg, setUser, acceptedTermsOfService, setAcceptedTermsOfService, setHasBeenWelcomed, hasBeenWelcomed} = useContext(AuthContext)

  useEffect(() => {
    const getProfile = async () => {
      try {
        let studentData = user
        if (!user){
            const student = await studentApi.getProfile()
            studentData = student.data
        }
        setUser(studentData)
        
        if (studentData && studentData.state && studentData.state.homepage){
            const homepage = studentData.state.homepage
            window.dataLayer.push({
                event: "userData",
                homepageGroup: homepage,
                homepageGroupVersion: "v1"
            })
        }
        
        // If it is user's first login, track this as a conversion
        // We have configured GA4 to only count this event once

        if (!localStorage.getItem('GA4_signup_recorded')){
            if (studentData && studentData.state && studentData.state.login && studentData.state.login === 1){
                window.dataLayer.push({
                    event: "sign_up",
                    user_id: getUserIDfromCookies(),
                    method: "normal",
                })
                localStorage.setItem('GA4_signup_recorded', 'true')
            }
        }

        setAcceptedTermsOfService(studentData.state?.acceptedTermsOfService || false)
        setHasBeenWelcomed(studentData.state.hasBeenWelcomed)

        const orgInfo = await orgApi.getOrg()
        const orgInfoData = orgInfo.data
        setWelcomesWithTOS(orgInfoData.snowflake.welcomeWithTermsOfService)
        setOrg(orgInfoData)
        
      } catch(e) {
        console.log(e)
      }

      setLoadingUser(false)
    }

    getProfile()
    
  }, [])
  
  if (loadingUser) {
    return (
        <LoadingPage />
    )
  }

//    if (true){
   if (!acceptedTermsOfService && welcomesWithTOS ){
    return (
        <WelcomeTOS org={org}/>
    )
  }

  if (!hasBeenWelcomed && user){

    return (
        <Welcome />
    )
  }

  return (
    <div className="app">

      <Hotjar user={user} />
      <StaffAccountRecord user={user}/>
      <BrowserNotifications />

      {/* <MessageBar snowflake={org.snowflake}/> */}
        <Switch>
          <Route exact path="/home" component={MentalHealth} />
          <Route exact path="/favourites" component={Favourites} />

          {/* Mental Health  */}
          <Route exact path="/mental-health" component={MentalHealth} />
          <Route exact path="/mental-health/complete" component={WaitForReccomendations} />
          <Route exact path="/mental-health/recommendations" component={Recommendations} />
          <Route exact path="/mental-health/all-resource" component={BrowseAllResources} />
          <Route exact path="/mental-health/mindfulness-resource" component={Mindfulness} />
          <Route exact path="/goback" component={GoBack} />

          {/* Fitness */}
          <Route exact path="/fitness" component={Fitness} />
          <Route exact path="/fitness/live-streams" component={LiveStreams} />
          <Route exact path="/fitness/on-campus" component={OnCampus} />
          <Route exact path="/fitness/on-demand" component={OnDemand} />

          {/* <Route exact path="/recommend" component={RecommendationsIntro} />
          <Route exact path="/recommend/new" component={CreateIntent} />
          <Route path="/recommend/:id" component={RecommendationsForIntent} /> */}

        </Switch>
      {/* <VideoInvite/> */}
    </div>
  );
};

export default withAuthentication()(AuthenticatedRoutes);
